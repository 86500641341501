import Sentry from '@shared/services/Sentry'

// Cache user lookup per load, as it is expensive
let cachedPromise = null

export default {
  methods: {
    async getUserProfile() {
      // Check promise cache or set it
      if (!cachedPromise) {
        cachedPromise = this.$request({
          method: 'get',
          url: '/api/user/current',
        })
      }

      const { data } = await cachedPromise
      data.appRoles = data?.appRoles || []
      data.groupRoles = data?.groupRoles || []

      // Set user in Sentry for future use in Replays
      try {
        Sentry.setUser({
          id: data?.id,
          username: data?.username 
        })
      } catch (e) {
        console.error(e)
      }

      // Merge manually assigned roles and group assigned roles
      data.roles = Array.from(
        new Set([
          ...data.appRoles.map(a => a.appRole.name),
          ...data.groupRoles.map(g => g.name),
        ])
      )

      return data
    },

    async getLicenseFilter(fieldName = 'synchroLocationId') {
      const user = await this.getUserProfile()
      if (user.allowAllLocations) {
        return
      }
      const locationIds = user
        .locations
        .map(l => l.locationId)
      const filter = [[fieldName, '=', locationIds.shift()]]
      for (const locationId of locationIds) {
        filter.push('or', [fieldName, '=', locationId])
      }
      return filter
    },

    async getLocationFilter(fieldName = 'coreLocationId') {
      const user = await this.getUserProfile()
      if (user.allowAllLocations) {
        return
      }
      const coreLocationIds = user
        .locations
        .map(l => l.location.coreLocationId)
      const locationIds = Array.from(new Set(coreLocationIds))
      const filter = [[fieldName, '=', locationIds.shift()]]
      for (const locationId of locationIds) {
        filter.push('or', [fieldName, '=', locationId])
      }
      return filter
    },
  },
}
